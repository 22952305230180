import $ from 'jquery'
import slick from 'slick-carousel'
import lightbox from 'lightbox2'
import Chart from 'chart.js/auto'
import LazyLoad from "vanilla-lazyload"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      autoplay: true,
      dots: true,
      infinite: true,
    },
    prize_money: {
      autoplay: true,
      dots: true,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    timeline: {
      autoplay: false,
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '24px',
        }
      }]
    },
    popup_works: {
      autoplay: false,
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* DROPDOWN TIMELINE
  ----------------------------- */
  export const dropdownTimeline = () => {
    $('#timeline__dropdown').on('change', function () {
      const selectedYear = $(this).val();
      const selectedSlide = $(`#${selectedYear}`).closest('.slick-slide');
  
      if (selectedSlide.length && selectedYear !== "0") {
        const slideIndex = selectedSlide.data('slick-index'); 
        $('.slider-init').slick('slickGoTo', slideIndex, true);
  
        $('.slick-slide').removeClass('slick-current');
        selectedSlide.addClass('slick-current');
      }
    });
  
    $('.slider-init').on('afterChange', function (event, slick, currentSlide) {
      const currentYear = $(`.slick-slide[data-slick-index="${currentSlide}"]`).find('.timeline__item').attr('id');
      if (currentYear) {
        $('#timeline__dropdown').val(currentYear);
      }
    });
  };

/* WORK DELETE POPUP + AJAX
  ----------------------------- */
export const workDeletePopup = () => {
  $('.button__delete-work').on('click', function(e) {
    e.preventDefault();

    const workId = $(this).data('work-id');
    const workTitle = $(this).data('work-title');

    const modalHTML = `
      <div id="modal-delete-work" class="modal-delete-work">
        <div class="modal-delete-work-content center">
          <h3>Attenzione</h3>
          <p>Sei sicuro di voler eliminare: "${workTitle}"?</p>
          <div class="modal__delete-buttons flex flex-wrap items-center justify-center">
            <a id="confirm-delete" class="modal-button button mx1" href="#">Conferma</a>
            <a id="cancel-delete" class="modal-button button button--red mx1" href="#">Annulla</a>
          </div>
        </div>
      </div>
    `;
    $('body').append(modalHTML);
    $('#modal-delete-work').fadeIn();
    
    $('#confirm-delete').on('click', function() {
      $.ajax({
        url: ajax_obj.ajaxurl,
        type: 'POST',
        data: {
            action: 'delete_work_post',
            work_id: workId,
            security: ajax_obj.security
        },
        success: function(response) {
          console.log(response); 
          if (response.success) {
              $('#modal-delete-work').fadeOut(function() {
                  $(this).remove();
              });
              location.reload();
          } else {
              alert(response.data.message || 'Errore nella cancellazione del post.');
          }
        },
        error: function(xhr, status, error) {
            console.error("Errore AJAX:", status, error);
            alert('Si è verificato un errore.');
        }
      });
    });

    $('#cancel-delete').on('click', function() {
      $('#modal-delete-work').fadeOut(function() {
        $(this).remove();
      });
    });
  });
};

/* BLOCK DELETE CANDIDATE
  ----------------------------- */
export const blockDeleteCandidate = () => {
  $('.preview__work.no-deletable-work').each(function() {
    $(this).find('.button__delete-work').remove();
  });
};

/* SHOW NAME FILE UPLOADED
  ----------------------------- */
  export const showNameFileUploaded = () => {
    $('#work_thumbnail').on('change', function(e) {
      var fileName = e.target.files.length > 0 ? e.target.files[0].name : 'Nessun file selezionato';
      $('#file-name').text(fileName);
    });

    $('.image-slot input').on('change', function(e) {
      var fileName = e.target.files.length > 0 ? e.target.files[0].name : 'Nessun file selezionato';
      $('#file-name').text(fileName);
    });

    $('#work_gallery').on('change', function(e) {
      var files = e.target.files;
      var fileNames = [];
      
      if (files.length > 0) {
          $.each(files, function(index, file) {
              fileNames.push(file.name); 
          });
          
          $('#file-name-gallery').text(fileNames.join(', '));
      } else {
          $('#file-name-gallery').text('Nessun file selezionato');
      }
    });
  };


/* DETECT VOTING
  ----------------------------- */
export const setupWorkPreference = () => {
  if ($('.preview__work-voting').length > 0) {

    $('body').on('click', '.preview__work-voting .section__voting .voting__button', function () {
      let work_id = $(this).data('work-id')
      let vote_n = $(this).data('vote-n')

      const wrapper_voting = $(this).closest('.wrapper__voting')
      wrapper_voting.find('.voting__button').removeClass('voted')
      for (var i = 1; i <= vote_n; i++) {
        wrapper_voting.find('.voting__button[data-vote-n="'+i+'"]').addClass('voted')
      }

      const loadingMessage = $('<div class="loading-message">Votazione in corso...</div>');
      $('body').append(loadingMessage);
      $('body').css('pointer-events', 'none');

      $.post(window.location.href,{
        action: 'save_work_preference',
        save_work_preference: 1,
        work_id: work_id,
        vote_n: vote_n,
        _wpnonce: ajax_obj.voting_nonce
      })
      .done(function () {
        loadingMessage.text('Voto salvato');
        setTimeout(function () {
          loadingMessage.remove();
          wrapper_voting.attr('data-total-vote-n', vote_n);

          $('body').css('pointer-events', 'auto');
        }, 1000);
      })
      .fail(function (xhr, status, error) {
        loadingMessage.text('Errore durante il salvataggio del voto, riprova più tardi');
        setTimeout(function () {
          loadingMessage.remove();
          $('body').css('pointer-events', 'auto');
        }, 1000);
      })
    })
  }
}

// DESCRIPTION NEW WORK REQUIRED
export const descriptionNewWorkRequired = () => {
  const form = $('form'); 
  const editorTextarea = $('textarea[name="work_content"]'); 

  if (form.length && editorTextarea.length) {
    form.on('submit', function (event) {
      if (typeof tinyMCE !== 'undefined' && tinyMCE.get('work_content')) {
        tinyMCE.triggerSave();
      }

      if (!editorTextarea.val().trim()) {
        event.preventDefault();
        alert('Il campo Descrizione è obbligatorio.');
        if (typeof tinyMCE !== 'undefined' && tinyMCE.get('work_content')) {
            tinyMCE.get('work_content').focus();
        }
      }
    });
  }
}

// TOGGLE VOTING POPUP WORKS SLIDER
export const toggleVotingPopupWorksSlider = () => {
  $('body').on('click', '.preview__work .open-popup', function () {
    $('.section__popup-works').addClass('open');
    $('body').css('overflow', 'hidden');

    let work_id = $(this).closest('.preview__work').data('work-id');

    $('.slider-init').slick('slickGoTo', parseInt(work_id, 10) - 1);
  });

  $('body').on('click', '.section__popup-works #close-slider-popup', function () {
    const loadingMessage = $('<div class="loading-message">Aggiornamento dati...</div>');
    $('.section__popup-works').removeClass('open');
    $('body').append(loadingMessage);
    $('body').css('pointer-events', 'none');
    location.reload();
  });
};

// GALLERY POPUP WORKS SLIDER
export const galleryPopupWorksSlider = () => {
  $('body').on('click', '.wrapper__gallery-preview .single__image', function () {
    var work = $(this).closest('.preview__work-popup');
    work.find('.single__image').removeClass('active');
    $(this).addClass('active');

    var image = $(this).find('img').attr('src');
    work.find('.preview__image img').remove();
    work.find('.preview__image figure').append('<img src="'+image+'" class="contain-image" alt="Preview image">');
  });
}

// REGISTRATION UNDER 41
export const registrationUnder41 = () => {
  $('#birthdate_field_field input.ur-flatpickr-field').on('change', function () {
    const birthDateInput = $(this).val();

    if (birthDateInput) {
        const birthDate = new Date(birthDateInput);
        const today = new Date();
        const currentYear = today.getFullYear();
        const minYear = currentYear - 39;
        

        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();

        const isOlderThanMinYear = birthDate.getFullYear() < minYear;

        const isOlderThan18 = age < 18 || (age === 18 && (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)));

        if (isOlderThanMinYear) {
          alert('Attenzione: il limite per potersi registrare è essere maggiorenni e nati a partire dal 01/01/' + minYear);
          $('.user-registration-multi-part-nav-next').css('display', 'none');
        } else if (isOlderThan18) { 
          alert('Attenzione: devi avere almeno 18 anni per poterti registrare');
          $('.user-registration-multi-part-nav-next').css('display', 'none');
        } else {
          $('.user-registration-multi-part-nav-next').css('display', 'block');
        }
    }
  });
}

// MOBILE DETECTION
export const mobileDetection = () => {
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
  const isTablet = (() => {
    if (navigator.userAgentData && navigator.userAgentData.brands) {
      return navigator.userAgentData.brands.some(brand => brand.brand === 'Apple' && navigator.maxTouchPoints > 1);
    }
    return /iPad/i.test(navigator.userAgent) || (navigator.maxTouchPoints > 1 && !/iPhone|Android/i.test(navigator.userAgent));
  })();

  const isPortrait = () => window.matchMedia("(orientation: portrait)").matches;
  const orientationMessage = $('<div class="mobile-message semibold">Ruota il dispositivo in orizzontale per continuare.</div>');
  const mobileMessage = $('<div class="mobile-message semibold">Questa pagina è disponibile solo su dispositivi Tablet o Computer.</div>');

  if (isMobile && !isTablet) {
    $('body').addClass('is-mobile');
    $('body').append(mobileMessage);
  } else if (isTablet) {
    $('body').addClass('is-tablet');

    if (isPortrait()) {
      $('body').append(orientationMessage);
    }
  } else if (isPortrait()) {
    $('body').append(orientationMessage);
  }

  const updateOrientationMessage = () => {
    const newIsPortrait = isPortrait();
    if (!newIsPortrait && !isMobile) {
      $('.mobile-message').remove();
    } else if (!$('.mobile-message').length) {
      $('body').append(orientationMessage);
    }
  };
  
  window.addEventListener('orientationchange', () => {
    setTimeout(updateOrientationMessage, 500);
  });

  window.addEventListener('resize', () => {
    setTimeout(updateOrientationMessage, 500);
  });
};

// ARCHIVE VOTING CUSTOM FILTERS
export const archiveVotingCustomFilters = () => {
  const updateUrl = (key, value) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    if (!value) {
      searchParams.delete(key);
    } else if (searchParams.get(key) === value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
      if(key === 'order_by' && value === 'vote') {
        searchParams.delete('work_rankings_filter');
      } else if(key === 'work_rankings_filter') {
        if(searchParams.get('order_by') === 'vote') {
          searchParams.delete('order_by');
        }
      }
    }

    let newSearchParams = new URLSearchParams();

    for (const [paramKey, paramValue] of searchParams.entries()) {
      newSearchParams.set(paramKey, paramValue);
    }

    currentUrl.search = newSearchParams.toString();
    window.history.pushState({ path: currentUrl.href }, '', currentUrl.href);
    location.reload();
  };

  $('.order__title').on('click', function () {
    updateUrl('order_by', 'title');
  });

  $('.order__vote').on('click', function () {
    updateUrl('order_by', 'vote');
  });

  $('.filter__work_rankings').on('change', function () {  
    const selectedOption = $(this).find('option:selected').val();
    updateUrl('work_rankings_filter', selectedOption);
  });

  $('.select_work_categories').on('change', function () {  
    const selectedOption = $(this).find('option:selected').val();
    updateUrl('work_categories_filter', selectedOption);
  });

  $('.select__rankings-checkbox').on('change', function () { 
    let selectedRankings = [];
    $('.select__rankings-checkbox').each(function() {
      if($(this).prop('checked')) {
        selectedRankings.push($(this).data('ranking-id'));
      }
    });
    selectedRankings = selectedRankings.join('-');
    updateUrl('tax_rankings_filter', selectedRankings);
  });
};

// POPUP LOGIN
export const popupLogin = () => {
  $('.account__login .button--login').on('click', function () {
    $('.account__login .popup__login').addClass('open');
    $('.popup__login-close').addClass('open');
  });

  $('.popup__login-close').on('click', function () {
    $('.account__login .popup__login').removeClass('open');
    $('.popup__login-close').removeClass('open');
  });

};

// LOST PASSWORD TRANSLATION
export const lostPasswordTranslation = () => {
  $('.user-registration-ur-lost-password .page__header .page__title').text('Reimpostazione password');
  $('.user-registration-ur-lost-password .page__content .user-registration-ResetPassword .ur-form-grid p').eq(0).text('Hai perso la password? Inserisci il tuo nome utente o indirizzo email. Riceverai un link per creare una nuova password tramite email.');
  
  if (window.location.search.includes('show-reset-form=true')) {
    $('.user-registration-ur-lost-password .page__content .user-registration-ResetPassword .ur-form-grid p').eq(0).text('Inserisci la nuova password e confermala.');
  }

  if (window.location.search.includes('reset-link-sent=true')) {
    $('.user-registration-ur-lost-password .page__content .user-registration-message').text('Il reset della password è stato richiesto. Controlla la tua email per completare la procedura.');
    $('.user-registration-ur-lost-password .page__content .user-registration > p').text('L\'email di reimpostazione della password è stata inviata all\'indirizzo email indicato, ma potrebbero volerci diversi minuti prima che venga visualizzata nella tua posta in arrivo. Attendi almeno 10 minuti prima di tentare un\'altra reimpostazione.');
  }
};

// REGISTRATION FORM TRANSLATION
export const registrationFormTranslation = () => {
  $('.user-registration-page .ur-multipart-form #registration_image_profile_field #ur_registration_image_profile .user-registration-file-upload-title').text('Clicca o trascina un file');
  $('.user-registration-page .ur-multipart-form #registration_image_profile_field #ur_registration_image_profile .user-registration-file-upload-hint').text('File supportati: JPG o PNG - Peso massimo: 1 MB');
  let characters = $('.user-registration-page .ur-multipart-form .field-textarea #registration_biography_field div > div:nth-child(2)').text();
  if(characters.includes('characters')) {
    characters = characters.replace('characters', 'caratteri');
    $('.user-registration-page .ur-multipart-form .field-textarea #registration_biography_field div > div:nth-child(2)').text(characters);
  }
};

/* JUDGE DELETE POPUP + AJAX
  ----------------------------- */
export const judgeDeletePopup = () => {
  $('.delete-judge').on('click', function(e) {
    e.preventDefault();

    const judgeId = $(this).data('judge-id');
    const judgeNickname = $(this).data('judge-nickname');

    const modalHTML = `
      <div id="modal-delete-judge" class="modal-delete-judge">
        <div class="modal-delete-judge-content center">
          <h3>Attenzione</h3>
          <p>Sei sicuro di voler eliminare: "${judgeNickname}"?</p>
          <div class="modal__delete-buttons flex flex-wrap items-center justify-center">
            <a id="confirm-delete" class="modal-button button mx1" href="#">Conferma</a>
            <a id="cancel-delete" class="modal-button button button--red mx1" href="#">Annulla</a>
          </div>
        </div>
      </div>
    `;
    $('body').append(modalHTML);
    $('#modal-delete-judge').fadeIn();
    
    $('#confirm-delete').on('click', function() {
      $('body').css('pointer-events', 'none');
      $.ajax({
        url: ajax_obj.ajaxurl,
        type: 'POST',
        data: {
            action: 'delete_user_judge',
            judge_id: judgeId,
            security: ajax_obj.security
        },
        success: function(response) {
          $('body').css('pointer-events', 'auto');
          if (response.success) {
              $('#modal-delete-judge').fadeOut(function() {
                  $(this).remove();
              });
              location.reload();
          } else {
              alert(response.data.message || 'Errore nella cancellazione del Giudice.');
          }
        },
        error: function(xhr, status, error) {
            console.error("Errore AJAX:", status, error);
            alert('Si è verificato un errore.');
        }
      });
    });

    $('#cancel-delete').on('click', function() {
      $('#modal-delete-judge').fadeOut(function() {
        $(this).remove();
      });
    });
  });
};

/* JUDGE FILTER EDITION
  ----------------------------- */
export const judgeFilterEdition = () => {
  $('.filter__edition').on('change', function () {
    const selectedOption = $(this).find('option:selected').val();
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    if (!selectedOption) {
      searchParams.delete('filter__edition');
    } else {
      searchParams.set('filter__edition', selectedOption);
    }

    let newSearchParams = new URLSearchParams();

    for (const [paramKey, paramValue] of searchParams.entries()) {
      newSearchParams.set(paramKey, paramValue);
    }

    currentUrl.search = newSearchParams.toString();
    window.history.pushState({ path: currentUrl.href }, '', currentUrl.href);
    location.reload();
  });
};

/* ARTIST DELETE POPUP + AJAX
  ----------------------------- */
export const artistDeletePopup = () => {
  $('.delete-artist').on('click', function(e) {
    e.preventDefault();

    const artistId = $(this).data('artist-id');
    const artistNickname = $(this).data('artist-nickname');

    const modalHTML = `
      <div id="modal-delete-artist" class="modal-delete-artist">
        <div class="modal-delete-artist-content center">
          <h3>Attenzione</h3>
          <p>Sei sicuro di voler eliminare: "${artistNickname}"?</p>
          <div class="modal__delete-buttons flex flex-wrap items-center justify-center">
            <a id="confirm-delete" class="modal-button button mx1" href="#">Conferma</a>
            <a id="cancel-delete" class="modal-button button button--red mx1" href="#">Annulla</a>
          </div>
        </div>
      </div>
    `;
    $('body').append(modalHTML);
    $('#modal-delete-artist').fadeIn();
    
    $('#confirm-delete').on('click', function() {
      $('body').css('pointer-events', 'none');
      $.ajax({
        url: ajax_obj.ajaxurl,
        type: 'POST',
        data: {
            action: 'delete_user_artist',
            artist_id: artistId,
            security: ajax_obj.security
        },
        success: function(response) {
          $('body').css('pointer-events', 'auto');
          if (response.success) {
              $('#modal-delete-artist').fadeOut(function() {
                  $(this).remove();
              });
              location.reload();
          } else {
              alert(response.data.message || 'Errore nella cancellazione del Giudice.');
          }
        },
        error: function(xhr, status, error) {
            console.error("Errore AJAX:", status, error);
            alert('Si è verificato un errore.');
        }
      });
    });

    $('#cancel-delete').on('click', function() {
      $('#modal-delete-artist').fadeOut(function() {
        $(this).remove();
      });
    });
  });
};


export const accordionJudgesVotes = () => {
  $('body').on('click', '.section__judges-votes .toggle__judges-votes', function () {
    $('.section__judges-votes').toggleClass('open');
    console.log('click function');
  });
}

/* ORDERS FILTER EDITION
  ----------------------------- */
  export const ordersFilterEdition = () => {
    $('.filter__products .select_products').on('change', function () {
      const selectedOption = $(this).find('option:selected').val();
      const currentUrl = new URL(window.location.href);
      const searchParams = currentUrl.searchParams;
  
      if (!selectedOption) {
        searchParams.delete('products_filter');
      } else {
        searchParams.set('products_filter', selectedOption);
      }
  
      let newSearchParams = new URLSearchParams();
  
      for (const [paramKey, paramValue] of searchParams.entries()) {
        newSearchParams.set(paramKey, paramValue);
      }
  
      currentUrl.search = newSearchParams.toString();
      window.history.pushState({ path: currentUrl.href }, '', currentUrl.href);
      location.reload();
    });

    $('.filter__bacs .checkbox__bacs').on('click', function () {
      const currentUrl = new URL(window.location.href);
      const searchParams = currentUrl.searchParams;

      if(searchParams.get('filter__bacs')) {
        searchParams.delete('filter__bacs');
      } else {
        searchParams.set('filter__bacs', 'bacs');
      }
  
      let newSearchParams = new URLSearchParams();
  
      for (const [paramKey, paramValue] of searchParams.entries()) {
        newSearchParams.set(paramKey, paramValue);
      }
  
      currentUrl.search = newSearchParams.toString();
      window.history.pushState({ path: currentUrl.href }, '', currentUrl.href);
      location.reload();
    });

    $('.filter__nocandidate .checkbox__nocandidate').on('click', function () {
      const currentUrl = new URL(window.location.href);
      const searchParams = currentUrl.searchParams;

      if(searchParams.get('filter__nocandidate')) {
        searchParams.delete('filter__nocandidate');
      } else {
        searchParams.set('filter__nocandidate', 'nocandidate');
      }
  
      let newSearchParams = new URLSearchParams();
  
      for (const [paramKey, paramValue] of searchParams.entries()) {
        newSearchParams.set(paramKey, paramValue);
      }
  
      currentUrl.search = newSearchParams.toString();
      window.history.pushState({ path: currentUrl.href }, '', currentUrl.href);
      location.reload();
    });
  };

/* DYNAMIC GALLERY */
export const dynamicGallery = () => {
  $('.image-slot input').on('change', function () {
    const index = $(this).data('id');
    previewImage(index);
  });

  $('.image-slot .remove-btn').on('click', function () {
    const index = $(this).data('id');
    removeImage(index);
  });

  function previewImage(index) {
    const input = document.getElementById(`file-${index}`);
    const previewContainer = document.getElementById(`preview-${index}`);
    const removeButton = document.getElementById(`remove-${index}`);
  
    if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = function(e) {
            previewContainer.innerHTML = `<img src="${e.target.result}" style="width: 100px; margin-top: 10px;">`;
            removeButton.style.display = "block";
        };
        reader.readAsDataURL(input.files[0]);
    }
  }

  function removeImage(index) {
      document.getElementById(`file-${index}`).value = "";
      document.getElementById(`preview-${index}`).innerHTML = "";
      document.getElementById(`remove-${index}`).style.display = "none";
      var label = document.getElementById(`label-${index}`);
      var fileName = label.querySelector('#file-name');
      fileName.textContent = 'Nessun file selezionato';
  }
}

