import $ from 'jquery'
import * as project from './functions'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  project.dropdownTimeline()
  project.blockDeleteCandidate()
  project.showNameFileUploaded()
  project.popupLogin()
  project.lostPasswordTranslation()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  if ($('.image-slot').length > 0) {
    project.dynamicGallery()
  }

  $(document).on('facetwp-refresh', function () {
    FWP.enable_scroll = FWP.soft_refresh == true
  })

  $(document).on('facetwp-loaded', function () {
      if (FWP.enable_scroll == true) $('html, body').animate({ scrollTop: 0 }, 500)
      project.lazyLoad()
  })

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        break
      case "logged-in":
        project.workDeletePopup()
        break
      case "page-template-add-new-work":
        project.descriptionNewWorkRequired()
        break
      case "page-template-voting":
        project.setupWorkPreference()
        project.toggleVotingPopupWorksSlider()
        project.galleryPopupWorksSlider()
        project.mobileDetection()
        project.archiveVotingCustomFilters()
        break
      case "post-type-archive-work":
        project.mobileDetection()
        break
      case "user-registration-page":
        project.registrationUnder41()
        project.registrationFormTranslation()
        break
      case "page-template-orders":
        project.ordersFilterEdition()
        project.mobileDetection()
        break
      default:
        $('#loader').fadeOut(500)
    }
  })

  if ($('#archive-judges-editable').length > 0) {
    project.mobileDetection()
    project.judgeDeletePopup()
    project.judgeFilterEdition()
  } else if ($('.judge-profile-editable.admin-editable').length > 0){
    project.mobileDetection()
  } else if ($('#archive-artists-editable').length > 0) {
    project.mobileDetection()
    project.artistDeletePopup()
  } else if ($('.section__voting-admin').length > 0) {
    project.accordionJudgesVotes()
  }
}
